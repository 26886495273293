<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" style="overflow:auto;">
        <div style="padding:20px 0px;width:95%;position:relatvie;margin:0 auto;" :style="{height:__tableHeight__+50+'px'}">
            <div style="text-align:center;position:relative;margin-bottom:30px;">
                <span style="font-size:26px;">入库单</span>
                <!-- <span style="color:#999;position:absolute;right:100px;top:20px;">单据编号: {{showData.number}}</span> -->
                <span style="color:#999;position:absolute;right:250px;top:20px;">关联采购单号: {{showData.record_number}}</span>
            </div>
            <!-- 顶部导航 -->
            <el-form ref="form" :inline="true" label-width="95px">
                <el-form-item label="入库仓库">
                    <label for="">{{showData.warehouse_info?showData.warehouse_info.name:''}}</label>
                </el-form-item>
                <el-form-item label="供应商">
                    <label for="">{{showData.supplier_info?showData.supplier_info.name:''}}</label>
                </el-form-item>
                <el-form-item label="经手人">
                    {{showData.user_info?showData.user_info.username:''}}
                </el-form-item>
                <el-form-item label="创建时间">
                    {{showData.business_date}}
                </el-form-item>
            </el-form>
            <!--  -->
            <div class="attr_row" style="margin:0 0 30px 0;">
                <table class="mytable" style="width:95%;">
                    <thead class="thead">
                        <tr class="rows ">
                            <td class="cols" >
                                <i class="el-icon-s-tools" style="font-size:16px;"></i>
                            </td>
                            <td class="cols">序号</td>
                            <td class="cols">商品编号</td>
                            <td class="cols">商品图片</td>
                            <td class="cols">商品名称</td>
                            <td class="cols">商品规格</td>
                            <td class="cols">预计入库量</td>
                            <td class="cols">实际入库量</td>
                            <td class="cols">单位</td>
                            <td class="cols">生产日期</td>
                            <td class="cols">备注</td>
                        </tr>
                    </thead>
                    <tbody class="tbody" v-if="goodsList.length > 0">
                        <tr class="rows purchase" :class="{odd:k%2===0}"  v-for="(i,k) in goodsList" :key="k">
                            <td class="cols">
                            </td>
                            <td class="cols">{{k+1}}</td>
                            <td class="cols">
                                <span>{{i.specification_code}}</span>
                            </td>
                            <td class="cols">
                                <span v-if="!!i.pic">
                                    <img style="width:30px;height:30px;" :src="baseUrl+'/'+i.pic" alt="">
                                </span>
                            </td>
                            <td class="cols">{{i.name}}</td>
                            <td class="cols">{{i.dispose_info}}</td>
                            <td class="cols">{{i.num}}</td>
                            <td class="cols">{{i.actual}}</td>
                            <td class="cols">
                                {{i.unit}}
                            </td>
                            <td class="cols">{{i.sum}}</td>
                            <td class="cols">{{i.date}}</td>
                        </tr>
                        <tr class="rows purchase" >
                            <td class="cols">
                                合计：
                            </td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols"></td>
                            <td class="cols">{{sumData.sumNum}}</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols">&nbsp;</td>
                            <td class="cols"></td>
                            <td class="cols">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <el-form ref="form" :inline="true" >
                <el-form-item label="单据备注：">
                    {{saveData.remarks?saveData.remarks:'没有备注'}}
                </el-form-item>
            </el-form>

            <el-form ref="form" :inline="true" >
                <el-form-item label="附件信息：">
                    <label for="" v-if="showData.enclosure.length>0">
                        <a style="display:inline-block;margin:0 10px;" :href="baseUrl+item" v-for="(item,key) in showData.enclosure" :key="key">附件{{key+1}}</a> 
                    </label>
                    <label v-else>
                        没有附件
                    </label>
                </el-form-item>
            </el-form>
        </div>
        <!-- 悬浮条 -->
        <div class="bottom_bar">
            <div style="width:90%;margin:0 auto;position:relative;">
                <div style="display:inline-block;position:absolute;top:0px;right:10px;">
                    <el-button @click="cancel">取消</el-button>
                    <el-button @click="printForm">打印</el-button>
                    <!-- <el-button @click="warehouseIn" type="primary">确认入库</el-button> -->
                </div>
            </div>
        </div>
        
        <el-dialog
        :modal-append-to-body="false"
        title="打印单据"
        :key="13"
        :visible.sync="printSimpleForm"
        v-if="printSimpleForm"
        width="60%"
        center>
            <printForm style="min-height:550px;" 
            v-if="printSimpleForm"
            :form_id="showData.id" 
            :tplType="showData.type"
            :typeId="showData.tp_type_id"
            @printLodopExit="printLodopExit"></printForm>
        </el-dialog>

        <!-- 选择商品 -->
        <el-dialog
            title="选择商品"
            :visible.sync="createShareFlag"
            :modal="false"
            :close-on-click-modal="false"
            v-if="createShareFlag"
            width="1180px"
            center>
            <createShare  :storeId="saveData.warehouse_id" 
                    @saveSelect="saveSelect"
                    @fleshList="fleshList" @shareExit="shareExit"></createShare>
        </el-dialog>

    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
import datas from "./objectData";
import methods from "./method";
import printForm from "../../print/printSimpleForm";

export default {
    props:{
        
    },
    inject: ['selectSide'], 
    data(){
        return datas;
    },
    destroyed(){
        this.goodsList = [];
        this.enclosure = [];
        this.sumData = {
            sumNum: 0,
            sumPrice: 0,
            allSumPrice: '0.00',
        };
        this.saveData = tools.copy(this.saveDataInit);
    },
    components:{
        createShare,shareListFlag,printForm
    },
    methods:methods,
    created(){
        if(this.saveDataInit === null){
            this.saveDataInit = tools.copy(this.saveData);
        }
        //初始化数据
        this.init();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.bottom_bar{
    min-width:100%;height:55px;background:#fff;position:fixed;line-height: 55px;
    bottom:0px;left:0px;z-index: 99;line-height:55px;text-align: left;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);border:1px solid #EBEEF5;
    .button{
        font-size:16px;margin-left:30px;z-index: 99;top:0px;width:130px;
    }
}
.el-input__inner{
    width:100%;background: #fff !important;;
}
.rows.purchase .cols{
    height: 40px;
}

</style>